import { useEffect } from "react";
import { useNavigation, useQuery } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
// import { DELETE_ENQUIRY } from "../../../../redux";
import {
  // deleteEnquiryAction,
  getEnquiryListAction,
} from "../../../../redux/actions/enquiry.action";

import { CompWrapper, Title, Card, Box, Table } from "../../../common";

export const ListEnquiry = () => {
  const {
    navigation: { navigate, routes },
    // history,
    // location,
  } = useNavigation();
  const query: any = useQuery();
  const dispatch = useDispatch();

  const pageNumber = query?.page || 1;

  const { enquiryList, totalCount, deleteLoading } = useSelector(
    (state: any) => state.enquiry
  );

  useEffect(() => {
    dispatch(getEnquiryListAction(pageNumber));
  }, [dispatch, pageNumber]);

  // const onDeleteHandler = (item: any, callback: any) => {
  //   dispatch(
  //     deleteEnquiryAction(item.contact_details.id, () => {
  //       if (enquiryList.length === 1) {
  //         dispatch({ type: DELETE_ENQUIRY.CLEAR });
  //         history?.replace(location.pathname + `?page=${pageNumber - 1}`);
  //       } else {
  //         dispatch({
  //           type: DELETE_ENQUIRY.SUCCESS,
  //           payload: item.contact_details.id,
  //         });
  //       }
  //       callback();
  //     })
  //   );
  // };

  // const editHandler = (item: any) => {
  //   navigate(`${routes["Teams"].path}/edit/${item.contact_details.id}`);
  // };

  const onViewHandler = (data: any) => {
    navigate(`${routes["Enquiry"].path}/view/${data.contact_details.id}`);
    // console.log("data", data);
  };

  return (
    <CompWrapper>
      <Card>
        <Box mb={20} flexBox alCenter jSpace>
          <Title size="medium">Enquiry</Title>
        </Box>
        <Box>
          <Table
            columns={[
              {
                field: "contact_details",
                name: "Name",
                render: (rowData: any) => `${rowData.name}`,
              },

              {
                field: "contact_details",
                name: "Message",
                render: (rowData: any) => `${rowData.message}`,
              },

              {
                field: "contact_details",
                name: "Email",
                render: (rowData: any) => `${rowData.email}`,
              },
              {
                field: "contact_details",
                name: "Phone",
                render: (rowData: any) => `${rowData.phone}`,
              },
              {
                field: "contact_details",
                name: "Subject",
                render: (rowData: any) => `${rowData.subject}`,
              },
            ]}
            data={enquiryList}
            dataLoader={false}
            totalCount={totalCount}
            actions
            onViewHandler={onViewHandler}
            // onDeleteHandler={onDeleteHandler}
            deleteLoader={deleteLoading}
            // onEditHandler={editHandler}
          />
        </Box>
      </Card>
    </CompWrapper>
  );
};
