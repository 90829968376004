import { apiGenerator } from "../helpers";

// export const BASE_URL = "http://167.71.228.52:8848/api"; // Dev Server API -
// export const FILE_URL = "http://167.71.228.52:8848/assets"; // Dev FILE URL

export const BASE_URL = "https://api.deepbrothers.com/api"; // prod Server API -
export const FILE_URL = "https://api.deepbrothers.com/assets"; // prod FILE URL

export const TABLE_LIMIT = 20;

// API FUNCTION
export const api: any = apiGenerator({ baseURL: BASE_URL });

export const urlType = {
  product: "product",
};

// API PATHS
export const APIS = {
  login: "/auth/login",
  logout: "/auth/logout",
  forget: "auth/forget",
  register: "auth/register",

  verify: "/auth/verify",
  forgot: "/auth/forget",

  change: "/auth/change",
  user: "/users",

  common: "/common",

  category: "/category",
  subcategory: "/subcategory",
  product: "/product",
  team: "/team",
  enquiry: "/contact",

  // product: `/common/type?name=${urlType.product}`,

  order: "/order",
};
