import {
  ACCEPTED_PRODUCT_LIST,
  ORDER_DETAIL,
  ORDER_LIST,
  ORDER_PRODUCT_LIST,
  UNFULFILLED_PRODUCT_LIST,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_PRODUCT_LIST,
  CLOSED_ORDER_LIST,
  UNFULFILLED_ORDER_LIST,
  FULFILLED_ORDER_LIST,
  DELETE_ORDER,
  SEND_NOTIFICATION,
} from "../actions";

const initalState: any = {
  orderListLoading: false,
  orderList: [],
  totalCount: null,

  orderDeleteLoading: false,

  sendNotificationLoading: false,

  detail: null,
  detailLoading: false,

  acceptedListLoading: true,
  acceptedList: [],

  allOrderProductListLoading: true,
  allOrderProductList: [],
  allOrderProductTotalCount: null,

  closedOrderListLoading: true,
  closedOrderList: [],
  closedOrderTotalCount: null,

  unfulfilledOrderListLoading: true,
  unfulfilledOrderList: [],
  unfulfilledOrderListTotalCount: null,

  fulfilledOrderListLoading: false,
  fulfilledOrderList: [],
  fulfilledOrderListTotalCount: null,

  unfullfilledListLoading: true,
  unfullfilledList: [],

  updateLoading: false,
  updateSuccess: false,
  updateError: false,

  manualNotificationLoading: false,
  manualNotificationSuccess: false,
  manualNotificationError: false,
};

export function orderReducer(state = initalState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case ORDER_LIST.CLEAR:
      return {
        ...state,
        orderListLoading: true,
        orderList: [],
        totalCount: null,
      };
    case ORDER_LIST.LOADING:
      return { ...state, orderListLoading: true, orderList: [] };
    case ORDER_LIST.SUCCESS:
      return {
        ...state,
        orderListLoading: false,
        orderList: payload.rows,
        totalCount: payload.total,
      };
    case ORDER_LIST.ERROR:
      return { ...state, orderListLoading: false };

    case DELETE_ORDER.LOADING:
      return {
        ...state,
        orderDeleteLoading: true,
      };

    case DELETE_ORDER.ERROR:
      return {
        ...state,
        orderDeleteLoading: false,
      };

    case DELETE_ORDER.SUCCESS:
      return {
        ...state,
        orderDeleteLoading: false,
      };

    case ORDER_DETAIL.LOADING:
      return { ...state, detailLoading: true, detail: null };
    case ORDER_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detail: payload,
      };
    case ORDER_DETAIL.ERROR:
      return { ...state, detailLoading: false };

    case ORDER_PRODUCT_LIST.CLEAR:
      return {
        ...state,
        allOrderProductList: [],
      };
    case ORDER_PRODUCT_LIST.LOADING:
      return {
        ...state,
        allOrderProductListLoading: true,
      };
    case ORDER_PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        allOrderProductListLoading: false,
        allOrderProductList: payload.rows,
        allOrderProductTotalCount: payload.total,
      };
    case ORDER_PRODUCT_LIST.ERROR:
      return { ...state, allOrderProductListLoading: false };

    case ACCEPTED_PRODUCT_LIST.CLEAR:
      return {
        ...state,
        acceptedListLoading: true,
        acceptedList: [],
      };
    case ACCEPTED_PRODUCT_LIST.LOADING:
      return { ...state, acceptedListLoading: true, acceptedList: [] };
    case ACCEPTED_PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        acceptedListLoading: false,
        acceptedList: payload,
        totalCount: payload.total,
      };
    case ACCEPTED_PRODUCT_LIST.ERROR:
      return { ...state, acceptedListLoading: false };

    case UNFULFILLED_PRODUCT_LIST.CLEAR:
      return {
        ...state,
        unfullfilledListLoading: true,
        unfullfilledList: [],
      };
    case UNFULFILLED_PRODUCT_LIST.LOADING:
      return { ...state, unfullfilledListLoading: true, unfullfilledList: [] };
    case UNFULFILLED_PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        unfullfilledListLoading: false,
        unfullfilledList: payload,
        totalCount: payload.total,
      };
    case UNFULFILLED_PRODUCT_LIST.ERROR:
      return { ...state, unfullfilledListLoading: false };

    case CLOSED_ORDER_LIST.CLEAR:
      return {
        ...state,
        closedOrderListLoading: true,
        closedOrderList: [],
      };
    case CLOSED_ORDER_LIST.LOADING:
      return { ...state, closedOrderListLoading: true, closedOrderList: [] };
    case CLOSED_ORDER_LIST.SUCCESS:
      return {
        ...state,
        closedOrderListLoading: false,
        closedOrderList: payload.rows,
        closedOrderTotalCount: payload.total,
      };
    case CLOSED_ORDER_LIST.ERROR:
      return { ...state, closedOrderListLoading: false };

    case UNFULFILLED_ORDER_LIST.CLEAR:
      return {
        ...state,
        unfulfilledOrderListLoading: true,
        unfulfilledOrderList: [],
      };
    case UNFULFILLED_ORDER_LIST.LOADING:
      return {
        ...state,
        unfulfilledOrderListLoading: true,
        unfulfilledOrderList: [],
      };
    case UNFULFILLED_ORDER_LIST.SUCCESS:
      console.log("payload", payload);
      return {
        ...state,
        unfulfilledOrderListLoading: false,
        unfulfilledOrderList: payload.rows,
        unfulfilledOrderListTotalCount: payload.total,
      };
    case UNFULFILLED_ORDER_LIST.ERROR:
      return { ...state, unfulfilledOrderListLoading: false };

    case FULFILLED_ORDER_LIST.CLEAR:
      return {
        ...state,
        fulfilledOrderListLoading: true,
        fulfilledOrderList: [],
      };
    case FULFILLED_ORDER_LIST.LOADING:
      return {
        ...state,
        fulfilledOrderListLoading: true,
        fulfilledOrderList: [],
      };
    case FULFILLED_ORDER_LIST.SUCCESS:
      console.log("payload", payload);
      return {
        ...state,
        fulfilledOrderListLoading: false,
        fulfilledOrderList: payload.rows,
        fulfilledOrderListTotalCount: payload.total,
      };
    case FULFILLED_ORDER_LIST.ERROR:
      return { ...state, fulfilledOrderListLoading: false };

    case UPDATE_ORDER_STATUS.LOADING:
      return { ...state, updateLoading: true };

    case UPDATE_ORDER_STATUS.SUCCESS: {
      return {
        ...state,
        updateLoading: false,
      };
    }

    case UPDATE_ORDER_STATUS.ERROR:
      return { ...state, updateLoading: false };

    case UPDATE_ORDER_PRODUCT_LIST.SUCCESS:
      const allOrderProductListClone = [...state.allOrderProductList];
      const orderIndex = allOrderProductListClone.findIndex(
        ({ order_item_details }) => order_item_details.id === payload.id
      );
      const orderProductClone = { ...allOrderProductListClone[orderIndex] };
      const order_item_detailsClone = {
        ...orderProductClone.order_item_details,
      };
      order_item_detailsClone["status"] = payload.status;
      orderProductClone["order_item_details"] = order_item_detailsClone;
      allOrderProductListClone[orderIndex] = orderProductClone;

      return {
        ...state,
        allOrderProductList: allOrderProductListClone,
      };

    case SEND_NOTIFICATION.LOADING:
      return {
        ...state,
        sendNotificationLoading: true,
      };

    case SEND_NOTIFICATION.ERROR:
      return {
        ...state,
        sendNotificationLoading: false,
      };

    case SEND_NOTIFICATION.SUCCESS:
      return {
        ...state,
        sendNotificationLoading: false,
      };

    default:
      return state;
  }
}
