import { useEffect } from "react";
import { useNavigation, useQuery } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { FILE_URL } from "../../../../config";
import {
  getTeamListAction,
  deleteTeamMemberAction,
  DELETE_TEAM_MEMBER,
} from "../../../../redux";

import { CompWrapper, Title, Card, Box, Table, Button } from "../../../common";

export const ListTeams = () => {
  const {
    navigation: { navigate, routes },
    history,
    location,
  } = useNavigation();
  const query: any = useQuery();
  const dispatch = useDispatch();

  const pageNumber = query?.page || 1;

  const { teamMemberList, totalCount, deleteLoading } = useSelector(
    (state: any) => state.team
  );

  useEffect(() => {
    dispatch(getTeamListAction(pageNumber));
  }, [dispatch, pageNumber]);

  const onDeleteHandler = (item: any, callback: any) => {
    dispatch(
      deleteTeamMemberAction(item.team_details.id, () => {
        if (teamMemberList.length === 1) {
          dispatch({ type: DELETE_TEAM_MEMBER.CLEAR });
          history?.replace(location.pathname + `?page=${pageNumber - 1}`);
        } else {
          dispatch({
            type: DELETE_TEAM_MEMBER.SUCCESS,
            payload: item.team_details.id,
          });
        }
        callback();
      })
    );
  };

  const editHandler = (item: any) => {
    navigate(`${routes["Teams"].path}/edit/${item.team_details.id}`);
  };

  // const onViewHandler = (data: any) => {
  //   navigate(`${routes["Teams"].path}/view/${data.team_details.id}`);
  //   // console.log("data", data);
  // };
  return (
    <CompWrapper>
      <Card>
        <Box mb={20} flexBox alCenter jSpace>
          <Title size="medium">Teams</Title>
          <Button
            title="Add Team Member"
            onClick={() => {
              navigate(routes["Add Team Member"].path);
            }}
          />
        </Box>
        <Box>
          <Table
            columns={[
              {
                field: "team_details",
                name: "Image",
                render: (rowData: any) => (
                  <img
                    crossOrigin="anonymous"
                    alt={rowData?.name}
                    src={`${FILE_URL}/team/${rowData?.image}`}
                    style={{ height: 100, borderRadius: 4 }}
                  />
                ),
              },

              {
                field: "team_details",
                name: "Name",
                render: (rowData: any) => `${rowData.name}`,
              },
              {
                field: "team_details",
                name: "Position",
                render: (rowData: any) => `${rowData.position}`,
              },
              {
                field: "team_details",
                name: "description",
                render: (rowData: any) => `${rowData.description}`,
              },
            ]}
            data={teamMemberList}
            dataLoader={false}
            totalCount={totalCount}
            actions
            // onViewHandler={onViewHandler}
            onDeleteHandler={onDeleteHandler}
            deleteLoader={deleteLoading}
            onEditHandler={editHandler}
          />
        </Box>
      </Card>
    </CompWrapper>
  );
};
