import { useState } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useInput } from "../../../hooks";
import { addTeamMemberAction, editTeamMemberAction } from "../../../redux";
import { isValid, validator } from "../../../utils";

import {
  Box,
  Button,
  FormInput,
  InputField,
  Media,
  Card,
  ActivityIndicator,
  TextArea,
} from "../../common";
import { FILE_URL } from "../../../config";
export const InputFormComponent = ({ detail }: any) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const [image, setImage] = useState([]);
  const [removedImage, setRemovedImage] = useState([]);
  const dispatch = useDispatch();
  const { addLoading, editLoading } = useSelector((state?: any) => state.team);

  const { data, onChangeHandler } = useInput({
    memberName: detail?.name ?? "",
    position: detail?.position ?? "",
    description: detail?.description ?? "",
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    const catchedErros = {};
    const validate = validator(catchedErros);

    const { memberName, position, description } = data;
    // VALIDATION
    const validateImage = () => {
      if (!!detail) {
        return (
          (image?.length === 0 &&
            removedImage?.length === detail?.image?.length) ||
          (image?.length === 0 &&
            removedImage?.length === 0 &&
            !!detail?.image?.length === false)
        );
      } else {
        return image?.length === 0;
      }
    };

    validate("imageFile", validateImage(), () => {
      toast.error("Image is Required");
    });
    validate("memberName", memberName?.length === 0, () => {
      toast.error("memberName is Required");
    });

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const formdata = new FormData();
    if (!detail || (!!detail && memberName !== detail.name)) {
      formdata.append("name", memberName);
    }
    if (!detail || (!!detail && position !== detail.position)) {
      formdata.append("position", position);
    }
    if (!detail || (!!detail && description !== detail.description)) {
      formdata.append("description", description);
    }

    image.length > 0 &&
      image.forEach((element) => {
        formdata.append("image", element.file);
      });

    !!detail
      ? dispatch(editTeamMemberAction(detail.id, formdata, goBackHandler))
      : dispatch(addTeamMemberAction(formdata, goBackHandler));
  };

  const goBackHandler = () => {
    // history.goBack();
    navigate(routes["Teams"].path);
  };

  return (
    <form onSubmit={onSubmit}>
      <Box flexBox>
        {/* <Box flex={1} /> */}
        <Box flex={2} flexBox vertical columnGap={20}>
          <Card title="Form">
            <Box flexBox vertical rowGap={20}>
              <FormInput label="Name" required>
                <InputField
                  placeholder="Name"
                  name="memberName"
                  value={data.memberName}
                  onChange={onChangeHandler("memberName")}
                  type="text"
                  // error={error?.name}
                  required
                />
              </FormInput>
              <FormInput label="Position" required>
                <InputField
                  placeholder="Position"
                  name="position"
                  value={data.position}
                  onChange={onChangeHandler("position")}
                  type="text"
                  // error={error?.position}
                  required
                />
              </FormInput>

              <FormInput label="Description" required>
                <TextArea
                  placeholder="Description"
                  name="description"
                  value={data.description}
                  onChange={onChangeHandler("description")}
                  // error={error?.description}
                />
              </FormInput>

              {/* <Box flexBox jEnd alCenter>
                <ActivityIndicator animating={false}>
                  <Button title="Submit" type="submit" />
                </ActivityIndicator>
              </Box> */}
            </Box>
          </Card>

          <Card>
            <Box flexBox vertical rowGap={20}>
              <Box flexBox jEnd alCenter>
                <ActivityIndicator animating={addLoading || editLoading}>
                  <Button.Ripple title="Submit" type="submit" />
                </ActivityIndicator>
              </Box>
            </Box>
          </Card>
        </Box>
        {/* <Box flex={1} /> */}
        <Box flex={2}>
          <Card title="Media">
            <Media
              setImage={setImage}
              // defaultImages={images}
              defaultImages={
                !!detail?.image ? [`${FILE_URL}/team/${detail?.image}`] : []
              }
              removedImage={removedImage}
              setRemovedImage={setRemovedImage}
            />
          </Card>
        </Box>
      </Box>
    </form>
  );
};
