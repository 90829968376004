import toast from "react-hot-toast";

import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  PRODUCT_DETAIL,
  PRODUCT_LIST,
  TOP_PRODUCT_LIST,
} from ".";
import { api, APIS, TABLE_LIMIT } from "../../config";

export function addProductAction(
  catId: number,
  formdata: any,
  goBackHandler: any
) {
  return async function (dispatch: any) {
    let res;
    try {
      dispatch({ type: ADD_PRODUCT.LOADING });
      res = await api(`${APIS.product}/${catId}`, "POST", formdata, {
        file: true,
      });
      console.log("addProductAction", res);
      const { success, data } = res.data;

      if (success) {
        dispatch({ type: ADD_PRODUCT.SUCCESS });
        toast.success("Product successfully Added");
        goBackHandler();
      } else {
        dispatch({ type: ADD_PRODUCT.ERROR });
        toast.error(data.message);
      }
    } catch ({ message }) {
      dispatch({ type: ADD_PRODUCT.ERROR });
      console.error(message);
      toast.error("Error Adding Product");
      // return 0;
    }
  };
}

// export function editProductAction(
//   id: any,
//   formData: any,
//   imageBody: any,
//   goBackHandler: any
// ) {
//   return async function (dispatch: any) {
//     let res;
//     try {
//       dispatch({ type: EDIT_PRODUCT.LOADING });

//       const removeImagePromise = new Promise(async (resolve, reject) => {
//         if (imageBody.index?.length === 0) {
//           return resolve("no remove");
//         } else {
//           let photoRes = await api(
//             `${APIS.product}/image/${id}`,
//             "DELETE",
//             imageBody
//           );
//           const { success: photoSuccess, data: photoData } = photoRes.data;
//           if (photoSuccess) {
//             resolve("success");
//           } else {
//             dispatch({ type: EDIT_PRODUCT.ERROR });
//             toast.error(photoData.message);
//             reject();
//           }
//         }
//       });

//       removeImagePromise.then(async () => {
//         res = await api(`${APIS.product}/${id}`, "PATCH", formData, {
//           file: true,
//         });

//         const { success, data } = res.data;
//         if (success) {
//           dispatch({ type: EDIT_PRODUCT.SUCCESS });
//           toast.success("Product successfully Edited");
//           goBackHandler();
//         } else {
//           dispatch({ type: EDIT_PRODUCT.ERROR });
//           toast.error(data.message);
//         }
//       });
//     } catch ({ message }) {
//       dispatch({ type: EDIT_PRODUCT.ERROR });
//       console.error(message);
//       toast.error("Error Editing Product");
//       // return 0;
//     }
//   };
// }

export function editProductAction(
  id: number,
  formdata: any,
  imageBody: any,
  goBackHandler: any
) {
  return async function (dispatch: any) {
    let res;
    try {
      dispatch({ type: EDIT_PRODUCT.LOADING });

      const removeImagePromise = new Promise(async (resolve, reject) => {
        if (imageBody?.index?.length === 0) {
          return resolve("no remove");
        } else {
          let photoRes = await api(
            `${APIS.product}/image/${id}`,
            "DELETE",
            imageBody
          );
          const { success: photoSuccess, data: photoData } = photoRes.data;
          if (photoSuccess) {
            resolve("success");
          } else {
            dispatch({ type: EDIT_PRODUCT.ERROR });
            toast.error(photoData.message);
            reject();
          }
        }
      });

      removeImagePromise.then(async () => {
        res = await api(`${APIS.product}/${id}`, "PATCH", formdata, {
          file: true,
        });
        const { success, data } = res.data;
        if (success) {
          dispatch({ type: EDIT_PRODUCT.SUCCESS });
          toast.success("Product successfully Edited");
          goBackHandler();
        } else {
          dispatch({ type: EDIT_PRODUCT.ERROR });
          toast.error(data.message);
        }
      });
    } catch ({ message }) {
      dispatch({ type: EDIT_PRODUCT.ERROR });
      console.error(message);
      toast.error("Error Editing Product");
      // return 0;
    }
  };
}

export function getProductListAction(page: any = 1) {
  return async function (dispatch: any) {
    let res;
    try {
      if (page === 1) {
        dispatch({ type: PRODUCT_LIST.CLEAR });
      }
      dispatch({ type: PRODUCT_LIST.LOADING });
      res = await api(`${APIS.product}?page=${page}&limit=${TABLE_LIMIT}`);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: PRODUCT_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: PRODUCT_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: PRODUCT_LIST.ERROR });
      console.error(message);
      // return 0;
    }
  };
}

export function getTopProductListAction(page: any = 1) {
  return async function (dispatch: any) {
    let res;
    try {
      if (page === 1) {
        dispatch({ type: TOP_PRODUCT_LIST.CLEAR });
      }
      dispatch({ type: TOP_PRODUCT_LIST.LOADING });
      res = await api(
        `${APIS.product}?is_top=true&page=${page}&limit=${TABLE_LIMIT}`
      );
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: TOP_PRODUCT_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: TOP_PRODUCT_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: TOP_PRODUCT_LIST.ERROR });
      console.error(message);
      // return 0;
    }
  };
}

export function getProductDetailAction(id: any) {
  return async function (dispatch: any) {
    let res;
    try {
      dispatch({ type: PRODUCT_DETAIL.LOADING });
      res = await api(`${APIS.product}/${id}`);
      const { success, data } = res.data;
      console.log("data", data);
      if (success) {
        dispatch({ type: PRODUCT_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: PRODUCT_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: PRODUCT_DETAIL.ERROR });
      console.error(message);
      // return 0;
    }
  };
}

export function deleteProductAction(id: any, callback: any) {
  return async function (dispatch: any) {
    let res;
    try {
      dispatch({ type: DELETE_PRODUCT.LOADING });
      res = await api(`${APIS.product}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success) {
        callback && callback();
        dispatch({ type: DELETE_PRODUCT.SUCCESS, payload: id });
        toast.success("Product successfully deleted");
        // dispatch(getProductAction(page));
      } else {
        dispatch({ type: DELETE_PRODUCT.ERROR });
        toast.error(data.message);
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_PRODUCT.ERROR });
      toast.error("Error Deleting Product");
      console.error(message);
      // return 0;
    }
  };
}
