import { useNavigation } from "react-auth-navigation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryDetailAction } from "../../../../redux";
import { CompWrapper } from "../../../common";
import { InputFormComponent } from "../commonFormComponent";

export const EditCategory = () => {
  const { params }: any = useNavigation();
  // const { id } = params;
  const dispatch = useDispatch();
  const { detail } = useSelector((state: any) => state.category);
  useEffect(() => {
    dispatch(getCategoryDetailAction(params?.id));
  }, [params?.id]);

  return (
    <CompWrapper>
      {!!detail ? (
        <InputFormComponent detail={detail} />
      ) : (
        <div>loading...</div>
      )}
    </CompWrapper>
  );
};
