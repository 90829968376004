import toast from "react-hot-toast";
import {
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  TEAM_DETAIL,
  TEAM_MEMBER_LIST,
} from ".";
import { api, APIS, TABLE_LIMIT } from "../../config";

export function addTeamMemberAction(formdata?: any, goBackHandler?: any) {
  return async function (dispatch?: any) {
    let res;
    try {
      dispatch({ type: ADD_TEAM_MEMBER.LOADING });
      res = await api(APIS.team, "POST", formdata, { file: true });

      const { success, data } = res.data;
      console.log("res.data", res.data);

      if (success) {
        dispatch({ type: ADD_TEAM_MEMBER.SUCCESS });
        toast.success(data.message);
        // toast({ message: "Category successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: ADD_TEAM_MEMBER.ERROR });
        toast.error(data.message);
      }
    } catch ({ message }) {
      dispatch({ type: ADD_TEAM_MEMBER.ERROR });
      console.error(message);
      toast.error("Error Adding Category");
      // return 0;
    }
  };
}

export function editTeamMemberAction(
  id: any,
  formdata?: any,
  goBackHandler?: any
) {
  return async function (dispatch?: any) {
    let res;
    try {
      dispatch({ type: EDIT_TEAM_MEMBER.LOADING });
      res = await api(`${APIS.team}/${id}`, "PATCH", formdata, {
        file: true,
      });

      const { success, data } = res.data;
      console.log("res.data", res.data);

      if (success) {
        dispatch({ type: EDIT_TEAM_MEMBER.SUCCESS });
        toast.success(data.message);
        // toast({ message: "Category successfully Added", type: "success" });
        goBackHandler();
      } else {
        dispatch({ type: EDIT_TEAM_MEMBER.ERROR });
        toast.error(data.message);
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_TEAM_MEMBER.ERROR });
      console.error(message);
      toast.error("Error Adding Category");
      // return 0;
    }
  };
}

export function getTeamListAction(page = 1) {
  return async function (dispatch?: any) {
    let res;
    try {
      if (page === 1) {
        dispatch({ type: TEAM_MEMBER_LIST.CLEAR });
      }
      dispatch({ type: TEAM_MEMBER_LIST.LOADING });
      res = await api(`${APIS.team}?page=${page}&limit=${TABLE_LIMIT}`);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: TEAM_MEMBER_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: TEAM_MEMBER_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: TEAM_MEMBER_LIST.ERROR });
      console.error(message);
      // return 0;
    }
  };
}

export function getTeamMemberDetailAction(id?: any) {
  return async function (dispatch?: any) {
    let res;
    try {
      dispatch({ type: TEAM_DETAIL.LOADING });
      res = await api(`${APIS.team}/${id}`);
      const { success, data } = res.data;
      console.log("data", data);
      if (success) {
        dispatch({ type: TEAM_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: TEAM_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: TEAM_DETAIL.ERROR });
      console.error(message);
      // return 0;
    }
  };
}

export function deleteTeamMemberAction(id?: any, callback?: any) {
  return async function (dispatch?: any) {
    let res;
    try {
      dispatch({ type: DELETE_TEAM_MEMBER.LOADING });
      res = await api(`${APIS.team}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success) {
        callback && callback();
        // dispatch({ type: DELETE_TEAM_MEMBER.SUCCESS, payload: id });
        toast.success("Category successfully deleted");
        // dispatch(getCategoryAction(page));
      } else {
        dispatch({ type: DELETE_TEAM_MEMBER.ERROR });
        toast.error(data.message);
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_TEAM_MEMBER.ERROR });
      toast.error("Error Deleting Category");
      console.error(message);
      // return 0;
    }
  };
}
