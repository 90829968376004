import {
  SUB_CATEGORY_LIST,
  SUB_CATEGORY_DETAIL,
  ADD_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  EDIT_SUB_CATEGORY,
  ALL_SUB_CATEGORY_LIST,
} from "../actions";

const initalState: any = {
  subCategoryListLoading: false,
  subCategoryList: [],
  totalCount: null,

  allSubCategoryListLoading: false,
  allSubCategoryList: [],
  allTotalCount: null,

  detail: null,
  detailLoading: false,

  addLoading: false,

  editLoading: false,

  deleteLoading: false,
};

interface SubCategoryDetailsProps {
  cat_id: number;
  id: number;
  image: string;
  name: string;
}
export function subCategoryReducer(state = initalState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case SUB_CATEGORY_LIST.CLEAR:
      return {
        ...state,
        subCategoryListLoading: true,
        subCategoryList: [],
      };
    case SUB_CATEGORY_LIST.LOADING:
      return { ...state, subCategoryListLoading: true, subCategoryList: [] };
    case SUB_CATEGORY_LIST.SUCCESS: {
      const ist = payload?.rows?.map(
        ({
          sub_category_details,
        }: {
          sub_category_details: SubCategoryDetailsProps;
        }) => {
          return { ...sub_category_details };
        }
      );
      return {
        ...state,
        subCategoryListLoading: false,
        subCategoryList: ist,
        totalCount: +payload.total,
      };
    }
    case SUB_CATEGORY_LIST.ERROR:
      return { ...state, subCategoryListLoading: false };

    case ALL_SUB_CATEGORY_LIST.CLEAR:
      return {
        ...state,
        allSubCategoryListLoading: true,
        allSubCategoryList: [],
      };
    case ALL_SUB_CATEGORY_LIST.LOADING:
      return {
        ...state,
        allSubCategoryListLoading: true,
        allSubCategoryList: [],
      };
    case ALL_SUB_CATEGORY_LIST.SUCCESS: {
      const ist = payload?.map(
        ({
          sub_category_details,
        }: {
          sub_category_details: SubCategoryDetailsProps;
        }) => {
          return { ...sub_category_details };
        }
      );
      return {
        ...state,
        allSubCategoryListLoading: false,
        allSubCategoryList: ist,
        allTotalCount: +payload.length,
      };
    }
    case ALL_SUB_CATEGORY_LIST.ERROR:
      return { ...state, allSubCategoryListLoading: false };

    case SUB_CATEGORY_DETAIL.LOADING:
      return { ...state, detailLoading: true, detail: null };
    case SUB_CATEGORY_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detail: payload,
      };
    case SUB_CATEGORY_DETAIL.ERROR:
      return { ...state, detailLoading: false };
    case ADD_SUB_CATEGORY.LOADING:
      return { ...state, addLoading: true };
    case ADD_SUB_CATEGORY.SUCCESS:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_SUB_CATEGORY.ERROR:
      return { ...state, addLoading: false };

    case EDIT_SUB_CATEGORY.LOADING:
      return { ...state, editLoading: true };
    case EDIT_SUB_CATEGORY.SUCCESS:
      return {
        ...state,
        editLoading: false,
      };
    case EDIT_SUB_CATEGORY.ERROR:
      return { ...state, editLoading: false };

    case DELETE_SUB_CATEGORY.LOADING:
      return { ...state, deleteLoading: true };
    case DELETE_SUB_CATEGORY.SUCCESS: {
      const subCategoryListClone = [...state.subCategoryList].filter(
        ({ id }) => id !== payload
      );
      return {
        ...state,
        deleteLoading: false,
        subCategoryList: subCategoryListClone,
      };
    }
    case DELETE_SUB_CATEGORY.CLEAR: {
      return {
        ...state,
        deleteLoading: false,
      };
    }

    case DELETE_SUB_CATEGORY.ERROR:
      return { ...state, deleteLoading: false };

    default:
      return state;
  }
}
