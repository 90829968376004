// import { useNavigation } from "react-auth-navigation";
import { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetailAction } from "../../../../redux";
import { CompWrapper } from "../../../common";
import { InputFormComponent } from "./../commonFormComponent";

export const EditProduct = () => {
  const { params }: any = useNavigation();
  const dispatch = useDispatch();
  const { detail } = useSelector((state: any) => state.product);
  useEffect(() => {
    dispatch(getProductDetailAction(params?.id));
  }, [params?.id]);
  console.log("detail", detail);
  return (
    <CompWrapper>
      {!!detail ? (
        <InputFormComponent detail={detail[0]} />
      ) : (
        <div>loading...</div>
      )}
    </CompWrapper>
  );
};
