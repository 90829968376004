import { BiCategory } from "react-icons/bi";
import { AiOutlineTeam } from "react-icons/ai";
import { MdNotes } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";

import {
  AddCategory,
  // AddPage,
  AddProduct,
  EditCategory,
  EditProduct,
  ListCategory,
  // ListOrder,
  // ListPage,
  ListProduct,
  LoginPage,
  AddTeams,
  EditTeams,
  ListTeams,
  ListEnquiry,
  ViewEnquiry,
  ProductHome,
  ListTopProduct,
} from "./pages";

// import { MdDashboard } from "react-icons/md";

// const Redirect = ({ to }: { to: any }): any => {
//   const {
//     navigation: { navigate },
//   } = useNavigation();

//   useEffect(() => {
//     navigate(to);
//   }, [to, navigate]);

//   return null;
// };

export const publicPaths = [
  {
    name: "Root",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    name: "Login",
    path: "/login",
    component: LoginPage,
    restricted: true,
  },
];

export const privatePaths = [
  // {
  //   name: "Sample",
  //   path: "/sample",
  //   component: ListPage,
  //   props: {
  //     icon: <MdDashboard size={20} />,
  //   },
  //   subPaths: [
  //     {
  //       name: "Add Sample",
  //       path: "/add",
  //       component: AddPage,
  //     },
  //   ],
  // },

  {
    name: "Category",
    path: "/category",
    component: ListCategory,
    props: {
      icon: <BiCategory />,
    },
    subPaths: [
      {
        name: "Add Category",
        path: "/add",
        component: AddCategory,
      },
      // {
      //   name: "View Category",
      //   path: "/view/:id",
      //   component: ListSubCategory,
      // },
      {
        name: "Edit Category",
        path: "/edit/:id",
        component: EditCategory,
      },
    ],
  },

  {
    name: "Product",
    path: "/product",
    component: ProductHome,
    props: {
      icon: <BsBoxSeam />,
    },
    nestedPaths: [
      {
        name: "All Product",
        path: "/all",
        component: ListProduct,
        props: {
          group: "Product",
        },
      },
      {
        name: "Top Product",
        path: "/top",
        component: ListTopProduct,
        props: {
          group: "Product",
        },
      },
    ],
    subPaths: [
      {
        name: "Add Product",
        path: "/add",
        component: AddProduct,
      },
      {
        name: "View Product",
        path: "/view/:id",
        component: ListProduct,
      },
      {
        name: "Edit Product",
        path: "/edit/:id",
        component: EditProduct,
      },
    ],
  },

  {
    name: "Teams",
    path: "/teams",
    component: ListTeams,
    props: {
      icon: <AiOutlineTeam />,
    },
    subPaths: [
      {
        name: "Add Team Member",
        path: "/add",
        component: AddTeams,
      },
      {
        name: "View Team Member",
        path: "/view/:id",
        component: ListTeams,
      },
      {
        name: "Edit Team Member",
        path: "/edit/:id",
        component: EditTeams,
      },
    ],
  },

  {
    name: "Enquiry",
    path: "/enquiry",
    component: ListEnquiry,
    props: {
      icon: <MdNotes />,
    },
    subPaths: [
      {
        name: "View Team Member",
        path: "/view/:id",
        component: ViewEnquiry,
      },
    ],
  },
];
