import {
  TEAM_MEMBER_LIST,
  TEAM_DETAIL,
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  // ALL_CATEGORY_LIST,
} from "../actions";

const initalState: any = {
  teamMemberListLoading: false,
  teamMemberList: [],
  totalCount: null,

  allCategoryListLoading: false,
  allCategoryList: [],

  detail: null,
  detailLoading: false,

  addLoading: false,

  editLoading: false,

  deleteLoading: false,
};

export function teamReducer(state = initalState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case TEAM_MEMBER_LIST.CLEAR:
      return {
        ...state,
        teamMemberListLoading: true,
        teamMemberList: [],
      };
    case TEAM_MEMBER_LIST.LOADING:
      return { ...state, teamMemberListLoading: true };
    case TEAM_MEMBER_LIST.SUCCESS:
      return {
        ...state,
        teamMemberListLoading: false,
        teamMemberList: payload.rows,
        totalCount: +payload.rows[0]?.total_count,
      };
    case TEAM_MEMBER_LIST.ERROR:
      return { ...state, teamMemberListLoading: false };

    // case ALL_CATEGORY_LIST.CLEAR:
    //   return {
    //     ...state,
    //     allCategoryListLoading: true,
    //     allCategoryList: [],
    //   };
    // case ALL_CATEGORY_LIST.LOADING:
    //   return { ...state, allCategoryListLoading: true, allCategoryList: [] };
    // case ALL_CATEGORY_LIST.SUCCESS: {
    //   const newdd = payload?.map(
    //     ({ team_details }: { team_details: any }) => {
    //       return { ...team_details };
    //     }
    //   );
    //   console.log("newdd", newdd);
    //   return {
    //     ...state,
    //     allCategoryListLoading: false,
    //     allCategoryList: newdd,
    //   };
    // }
    // case ALL_CATEGORY_LIST.ERROR:
    //   return { ...state, allCategoryListLoading: false };

    case TEAM_DETAIL.LOADING:
      return { ...state, detailLoading: true, detail: null };
    case TEAM_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detail: payload,
      };
    case TEAM_DETAIL.ERROR:
      return { ...state, detailLoading: false };
    case ADD_TEAM_MEMBER.LOADING:
      return { ...state, addLoading: true };
    case ADD_TEAM_MEMBER.SUCCESS:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_TEAM_MEMBER.ERROR:
      return { ...state, addLoading: false };

    case EDIT_TEAM_MEMBER.LOADING:
      return { ...state, editLoading: true };
    case EDIT_TEAM_MEMBER.SUCCESS:
      return {
        ...state,
        editLoading: false,
      };
    case EDIT_TEAM_MEMBER.ERROR:
      return { ...state, editLoading: false };

    case DELETE_TEAM_MEMBER.LOADING:
      return { ...state, deleteLoading: true };
    case DELETE_TEAM_MEMBER.SUCCESS: {
      console.log("payload", payload);
      console.log("state.teamMemberList", state.teamMemberList);
      const teamMemberListClone = [...state.teamMemberList].filter(
        ({ team_details }) => team_details.id !== payload
      );
      return {
        ...state,
        deleteLoading: false,
        teamMemberList: teamMemberListClone,
      };
    }
    case DELETE_TEAM_MEMBER.CLEAR: {
      return {
        ...state,
        deleteLoading: false,
      };
    }

    case DELETE_TEAM_MEMBER.ERROR:
      return { ...state, deleteLoading: false };

    default:
      return state;
  }
}
