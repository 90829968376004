export const userType = {
  ADMIN: "ADMIN",
  USER: "user",
};

export const userRoles = {
  [userType.ADMIN]: {
    access: ["/", "/category/*", "/product/*", "/teams/*", "/enquiry/*"],
  },
  [userType.USER]: {
    access: ["/", "/login"],
  },
};
