import { ENQUIRY_LIST, ENQUIRY_DETAIL, DELETE_ENQUIRY } from "../actions";

const initalState: any = {
  enquiryListLoading: false,
  enquiryList: [],
  totalCount: null,

  detail: null,
  detailLoading: false,

  deleteLoading: false,
};

export function enquiryReducer(state = initalState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case ENQUIRY_LIST.CLEAR:
      return {
        ...state,
        enquiryListLoading: true,
        enquiryList: [],
      };
    case ENQUIRY_LIST.LOADING:
      return { ...state, enquiryListLoading: true };
    case ENQUIRY_LIST.SUCCESS:
      console.log("payload", payload);
      return {
        ...state,
        enquiryListLoading: false,
        enquiryList: payload.rows,
        totalCount: +payload.rows[0]?.total_count,
      };
    case ENQUIRY_LIST.ERROR:
      return { ...state, enquiryListLoading: false };

    case ENQUIRY_DETAIL.LOADING:
      return { ...state, detailLoading: true, detail: null };
    case ENQUIRY_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detail: payload,
      };
    case ENQUIRY_DETAIL.ERROR:
      return { ...state, detailLoading: false };

    case DELETE_ENQUIRY.LOADING:
      return { ...state, deleteLoading: true };
    case DELETE_ENQUIRY.SUCCESS: {
      console.log("payload", payload);
      console.log("state.enquiryList", state.enquiryList);
      const teamMemberListClone = [...state.enquiryList].filter(
        ({ team_details }) => team_details.id !== payload
      );
      return {
        ...state,
        deleteLoading: false,
        enquiryList: teamMemberListClone,
      };
    }
    case DELETE_ENQUIRY.CLEAR: {
      return {
        ...state,
        deleteLoading: false,
      };
    }

    case DELETE_ENQUIRY.ERROR:
      return { ...state, deleteLoading: false };

    default:
      return state;
  }
}
