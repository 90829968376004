import { combineReducers } from "redux";
import { categoryReducer } from "./category.reducer";
import { enquiryReducer } from "./enquiry.reducer";
import { loginReducer } from "./login.reducer";
import { orderReducer } from "./order.reducer";
import { productReducer } from "./product.reducer";
import { subCategoryReducer } from "./subCategory.reducer";
import { teamReducer } from "./team.reducer";
import { userReducer } from "./user.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  category: categoryReducer,
  subCategory: subCategoryReducer,
  product: productReducer,
  order: orderReducer,
  team: teamReducer,
  enquiry: enquiryReducer,
});

export default rootReducer;
