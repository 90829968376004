// import { CompWrapper } from "../../../common";

import { useEffect } from "react";
import { Auth, useNavigation } from "react-auth-navigation";
import { Box, CompWrapper, TabAuth } from "../../../common";

export const ProductHome = () => {
  const {
    navigation: { navigate },
    location: { pathname },
  } = useNavigation();

  useEffect(() => {
    if (pathname === "/product" || pathname === "/product/") {
      navigate("/product/all");
    }
  }, [navigate, pathname]);
  return (
    <CompWrapper>
      {/* <Header /> */}
      <TabAuth group="Product" />
      <Box mt={10}>
        <Auth.Screens path="/product" />
      </Box>
    </CompWrapper>
  );
};
