import { CompWrapper } from "../../../common";
import { InputFormComponent } from "../commonFormComponent";

export const AddCategory = () => {
  return (
    <CompWrapper>
      <InputFormComponent />
    </CompWrapper>
  );
};
