import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { useInput } from "../../../hooks";
import {
  addProductAction,
  editProductAction,
  getAllCategoryListAction,
} from "../../../redux";

import { isValid, validator } from "../../../utils";

import {
  Box,
  Button,
  FormInput,
  InputField,
  Media,
  // SelectField,
  TextEditor,
  ActivityIndicator,
  Card,
  SelectField,
} from "../../common";
import { FILE_URL } from "../../../config";
import { CheckBox } from "../../common/checkbox/checkbox";

interface specificationType {
  name: String;
  value: String;
  _id: any;
}

interface DefaultSpecificationType {
  name: String;
  value: String;
}

interface CategoryType {
  total_count: string;
  category_details: {
    id: number;
    name: string;
    image: string;
  };
}

interface ProductItem {
  product_details: {
    id: number;
    name: string;
    image: string[] | null;
    cat_id: number;
    description: string;
    model: string;
    price: string | number;
    specification: string;
    is_top: boolean;
  };
  category_details: {
    id: number;
    name: string;
    image: string;
  };
}
interface ProductDetailProps {
  detail?: ProductItem;
}
export const InputFormComponent = ({ detail }: ProductDetailProps) => {
  const { product_details } = !!detail && detail;

  const [descriptionBody, setDescriptionBody] = useState(() => {
    return product_details?.description.slice(1, -1) ?? "";
  });

  const { history } = useNavigation();
  const [image, setImage] = useState([]);
  const [removedImage, setRemovedImage] = useState([]);
  const [isTopProduct, setIsTopProduct] = useState(() =>
    !!detail ? detail.product_details.is_top : false
  );
  const getSpecsList = () => {
    const defaultSpecification = !!product_details?.specification
      ? JSON?.parse(product_details?.specification)
      : null;

    if (defaultSpecification?.length > 0) {
      const ssss = defaultSpecification?.map(
        ({ name, value }: DefaultSpecificationType) => {
          return { name, value, _id: uuidv4() };
        }
      );
      return ssss;
    }
    return [{ name: "", value: "", _id: uuidv4() }];
  };

  const [specification, setSpecification] = useState<specificationType[]>([
    ...getSpecsList(),
  ]);

  const dispatch = useDispatch();
  const { addLoading, editLoading } = useSelector(
    (state: any) => state.product
  );
  const { allCategoryList } = useSelector((state: any) => state.category);

  // useEffect(() => {
  //   dispatch(getCategoryListAction());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getAllCategoryListAction());
  }, [dispatch]);

  const { data, onChangeHandler } = useInput({
    name: product_details?.name ?? "",
    catId: product_details?.cat_id ?? "",
    model: product_details?.model ?? "",
    price: product_details?.price ?? "",
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    const catchedErros = {};
    const validate = validator(catchedErros);

    const {
      catId,

      name,
      model,
      price,
    } = data;

    // VALIDATION
    const validateImage = () => {
      if (!!detail) {
        return (
          (image?.length === 0 &&
            removedImage?.length === product_details.image?.length) ||
          (image?.length === 0 &&
            removedImage?.length === 0 &&
            !!product_details.image?.length === false)
        );
      } else {
        return image?.length === 0;
      }
    };

    validate("imageFile", validateImage(), () => {
      toast.error("Image is Required");
    });

    validate("descriptionBody", descriptionBody?.length === 0, () => {
      toast.error("Description is Required");
    });

    validate("catId", catId?.length === 0, () => {
      toast.error("Category is Required");
    });

    validate("name", name?.length === 0, () => {
      toast.error("Name is Required");
    });

    validate("price", price?.length === 0, () => {
      toast.error("Price is Required");
    });

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const specificationaa = specification.map(({ name, value }) => {
      return { name, value };
    });

    // const body = {
    //   name: name,
    //   description: JSON.stringify(descriptionBody),
    //   type: urlType.product,
    //   model: model,
    //   specification: JSON.stringify(specificationaa),
    // };

    const imageBody = {
      index: removedImage,
    };

    const formdata = new FormData();

    formdata.append("name", name);
    formdata.append("model", model);
    formdata.append("price", price);
    formdata.append("isTop", String(isTopProduct));
    formdata.append("description", JSON.stringify(descriptionBody));
    formdata.append("specification", JSON.stringify(specificationaa));
    // return;
    image.length > 0 &&
      image.forEach((element) => {
        formdata.append("image", element.file);
      });

    !!detail
      ? dispatch(
          editProductAction(
            detail?.product_details?.id,
            formdata,
            imageBody,
            goBackHandler
          )
        )
      : dispatch(addProductAction(Number(catId), formdata, goBackHandler));
  };

  const goBackHandler = () => {
    history.goBack();
  };

  const onRemoveHandler = (index: any) => {
    const specClone = [...specification];
    specClone.splice(index, 1);
    setSpecification(specClone);
  };

  return (
    <form onSubmit={onSubmit}>
      <Box flexBox>
        <Box flex={2}>
          <Card>
            <Box flexBox vertical rowGap={20}>
              <FormInput label="Product Name" required>
                <InputField
                  placeholder="Product Name"
                  name="name"
                  value={data.name}
                  onChange={onChangeHandler("name")}
                  type="text"
                  // error={error?.name}
                  required
                />
              </FormInput>

              <FormInput label="Category" required>
                <SelectField
                  options={allCategoryList}
                  // isMulti
                  getOptionLabel={(option: CategoryType) =>
                    option.category_details.name
                  }
                  getOptionValue={(option: CategoryType) =>
                    option.category_details.id
                  }
                  onChangeValue={(item) => {
                    console.log(item);
                    onChangeHandler("catId", item.category_details?.id)();
                  }}
                  isSearchable
                  placeholder="Select Category"
                  value={allCategoryList.find(({ category_details }: any) => {
                    return category_details.id === data.catId;
                  })}
                  isDisabled={!!detail}
                />
              </FormInput>

              <FormInput label="Model" required>
                <InputField
                  placeholder="Model"
                  name="model"
                  value={data.model}
                  onChange={onChangeHandler("model")}
                  type="text"
                  // error={error.price}
                  required
                  // min={0}
                />
              </FormInput>
              <FormInput label="Price" required>
                <InputField
                  placeholder="Price"
                  name="price"
                  value={data.price}
                  onChange={onChangeHandler("price")}
                  type="text"
                  // error={error.price}
                  required
                  min={0}
                />
              </FormInput>

              <FormInput label="Is Top Product?" required>
                <YesNoCheckBox
                  truth={isTopProduct}
                  setTruth={setIsTopProduct}
                />
              </FormInput>

              <FormInput label="Specification" required>
                <Box flexBox vertical rowGap={10}>
                  {specification.map((item, index) => {
                    return (
                      <Box flexBox key={item._id}>
                        <SpecsDetail
                          {...{ specification, setSpecification, index }}
                          onRemove={() => onRemoveHandler(index)}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </FormInput>
              <Box>
                <Button
                  type="button"
                  title="Add Specs"
                  onClick={(e) => {
                    e.preventDefault();
                    setSpecification((prev) => [
                      ...prev,
                      { name: "", value: "", _id: uuidv4() },
                    ]);
                  }}
                />
              </Box>

              <FormInput label="Description" required>
                <TextEditor
                  descriptionBody={descriptionBody}
                  onChange={setDescriptionBody}
                />
              </FormInput>

              <Box flexBox jEnd alCenter>
                <ActivityIndicator animating={addLoading || editLoading}>
                  <Button title="Submit" type="submit" />
                </ActivityIndicator>
              </Box>
            </Box>
          </Card>
        </Box>
        <Box flex={1} flexBox vertical>
          <Card title="Media">
            <Media
              // image={image}
              setImage={setImage}
              multiple={true}
              defaultImages={product_details?.image?.map(
                (image: string) => `${FILE_URL}/product/${image}`
              )}
              removedImage={removedImage}
              setRemovedImage={setRemovedImage}
            />
          </Card>

          {/* <Card title="Tags">
            <FormInput label="Tags">
              <TagsInput placeHolder="Tags" value={tags} onChange={setTags} />
            </FormInput>
          </Card> */}
        </Box>
      </Box>
    </form>
  );
};

interface SpecsDetailPropType {
  index: any;
  specification: any[];
  setSpecification: Dispatch<SetStateAction<any[]>>;
  onRemove: any;
}
const SpecsDetail = ({
  specification,
  setSpecification,
  index,
  onRemove,
}: SpecsDetailPropType) => {
  const specClone = [...specification];
  const { data, onChangeHandler } = useInput({
    name: specClone[index]?.name ?? "",
    value: specClone[index]?.value ?? "",
  });

  useEffect(() => {
    const { name, value } = data;
    const thisSpecs = { ...specClone[index] };
    specClone[index] = {
      ...thisSpecs,
      name,
      value,
    };
    setSpecification(specClone);
  }, [data.name, data.value]);
  return (
    <Box flexBox>
      <InputField
        placeholder="Name"
        name="name"
        value={data.name}
        onChange={onChangeHandler("name")}
        type="text"
        // error={error.name}
        required
        min={0}
      />

      <InputField
        placeholder="Value"
        name="value"
        value={data.value}
        onChange={onChangeHandler("value")}
        type="text"
        // error={error.value}
        required
        min={0}
      />
      {specification?.length > 1 ? (
        <MdDelete
          color="red"
          size={40}
          onClick={onRemove}
          style={{ cursor: "pointer" }}
        />
      ) : null}
    </Box>
  );
};

interface YesNoCheckBoxTypes {
  truth: boolean;
  setTruth: (arg: boolean) => void;
}
const YesNoCheckBox = ({ truth, setTruth }: YesNoCheckBoxTypes) => {
  return (
    <Box flexBox>
      <CheckBox
        label="Yes"
        name="minor"
        handleCheckboxChange={() => {
          setTruth(true);
        }}
        check={truth}
      />
      <CheckBox
        label="NO"
        name="minor"
        handleCheckboxChange={() => {
          setTruth(false);
        }}
        check={!truth}
      />
    </Box>
  );
};
