import {
  CATEGORY_LIST,
  CATEGORY_DETAIL,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  ALL_CATEGORY_LIST,
} from "../actions";

const initalState: any = {
  categoryListLoading: false,
  categoryList: [],
  totalCount: null,

  allCategoryListLoading: false,
  allCategoryList: [],

  detail: null,
  detailLoading: false,

  addLoading: false,

  editLoading: false,

  deleteLoading: false,
};

export function categoryReducer(state = initalState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_LIST.CLEAR:
      return {
        ...state,
        categoryListLoading: true,
        categoryList: [],
      };
    case CATEGORY_LIST.LOADING:
      return { ...state, categoryListLoading: true, categoryList: [] };
    case CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        categoryListLoading: false,
        categoryList: payload.rows,
        totalCount: +payload.rows[0]?.total_count,
      };
    case CATEGORY_LIST.ERROR:
      return { ...state, categoryListLoading: false };

    case ALL_CATEGORY_LIST.CLEAR:
      return {
        ...state,
        allCategoryListLoading: true,
        allCategoryList: [],
      };
    case ALL_CATEGORY_LIST.LOADING:
      return { ...state, allCategoryListLoading: true, allCategoryList: [] };
    case ALL_CATEGORY_LIST.SUCCESS: {
      // const newdd = payload?.map(
      //   ({ category_details }: { category_details: any }) => {
      //     return { ...category_details };
      //   }
      // );
      // console.log("newdd", newdd);
      return {
        ...state,
        allCategoryListLoading: false,
        allCategoryList: payload,
      };
    }
    case ALL_CATEGORY_LIST.ERROR:
      return { ...state, allCategoryListLoading: false };

    case CATEGORY_DETAIL.LOADING:
      return { ...state, detailLoading: true, detail: null };
    case CATEGORY_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detail: payload,
      };
    case CATEGORY_DETAIL.ERROR:
      return { ...state, detailLoading: false };
    case ADD_CATEGORY.LOADING:
      return { ...state, addLoading: true };
    case ADD_CATEGORY.SUCCESS:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_CATEGORY.ERROR:
      return { ...state, addLoading: false };

    case EDIT_CATEGORY.LOADING:
      return { ...state, editLoading: true };
    case EDIT_CATEGORY.SUCCESS:
      return {
        ...state,
        editLoading: false,
      };
    case EDIT_CATEGORY.ERROR:
      return { ...state, editLoading: false };

    case DELETE_CATEGORY.LOADING:
      return { ...state, deleteLoading: true };
    case DELETE_CATEGORY.SUCCESS: {
      console.log("payload", payload);
      console.log("state.categoryList", state.categoryList);
      const categoryListClone = [...state.categoryList].filter(
        ({ category_details }) => category_details.id !== payload
      );
      return {
        ...state,
        deleteLoading: false,
        categoryList: categoryListClone,
      };
    }
    case DELETE_CATEGORY.CLEAR: {
      return {
        ...state,
        deleteLoading: false,
      };
    }

    case DELETE_CATEGORY.ERROR:
      return { ...state, deleteLoading: false };

    default:
      return state;
  }
}
