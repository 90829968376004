import { useEffect } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { getEnquiryDetailAction } from "../../../../redux/actions/enquiry.action";
import { Box, CompWrapper, Italic } from "../../../common";
import { KeyValueTable } from "../../../common/keyValueTable/keyValueTable.component";
// import { InputFormComponent } from "../commonFormComponent";

export const ViewEnquiry = () => {
  const { params }: any = useNavigation();
  const dispatch = useDispatch();

  const { detail } = useSelector((state: any) => state.enquiry);
  useEffect(() => {
    dispatch(getEnquiryDetailAction(params.id));
  }, [params.id, dispatch]);

  console.log("detail", detail);
  if (!detail) return <></>;

  return (
    <CompWrapper>
      <Box flexBox>
        <Box>
          <Box>
            subject
            <Italic>detail.subject</Italic>
          </Box>
          <KeyValueTable
            data={[
              { title: "name", value: detail.name },
              { title: "subject", value: detail.subject },
              { title: "email", value: detail.email },
              { title: "phone", value: detail.phone },
              { title: "message", value: detail.message },
              {
                title: "product",
                value: !!detail?.product_id ? (
                  <div
                    onClick={() =>
                      window.open(
                        `http://localhost:3011/product/${detail.product_id}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {detail.product_id}{" "}
                  </div>
                ) : null,
              },
              {
                title: "quantity",
                value: !!detail?.quantity ? <>{detail.quantity} </> : null,
              },
            ]}
          />
        </Box>
      </Box>
    </CompWrapper>
  );
};
