import { useEffect } from "react";
import { useNavigation, useQuery } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { FILE_URL } from "../../../../config";

import {
  getTopProductListAction,
  deleteProductAction,
  DELETE_PRODUCT,
  editProductAction,
} from "../../../../redux";

import { Card, Title, Box, Table } from "../../../common";

interface ProductItem {
  category_details: {
    id: number;
    name: string;
    image: string;
  };
  product_details: {
    id: number;
    name: string;
    image: string[] | null;
    cat_id: number;
    description: string;
    model: string;
    specification: string;
    is_top: boolean;
  };
}
export const ListTopProduct = () => {
  const {
    navigation: { navigate, routes },
    history,
    location,
  } = useNavigation();
  const query: any = useQuery();

  const dispatch = useDispatch();

  const pageNumber = query?.page || 1;

  const { topProductList, topProductTotalCount, deleteLoading } = useSelector(
    (state: any) => state.product
  );

  useEffect(() => {
    dispatch(getTopProductListAction(pageNumber));
  }, [dispatch, pageNumber]);

  const onDeleteHandler = (item: any, callback: any) => {
    dispatch(
      deleteProductAction(item?.product_details.id, () => {
        if (topProductList.length === 1) {
          dispatch({ type: DELETE_PRODUCT.CLEAR });
          history.replace(location.pathname + `?page=${pageNumber - 1}`);
        } else {
          dispatch({
            type: DELETE_PRODUCT.SUCCESS,
            payload: item?.product_details.id,
          });
        }
        callback();
      })
    );
  };

  const editHandler = (item: any) => {
    navigate(`${routes["Product"].path}/edit/${item.product_details.id}`);
  };

  const updateHandler = (item: ProductItem, callback: () => {}) => {
    const formdata = new FormData();
    formdata.append("isTop", String(!item?.product_details?.is_top));
    dispatch(
      editProductAction(
        item.product_details.id,
        formdata,
        { index: [] },
        () => {
          callback();
          dispatch(getTopProductListAction(pageNumber));
        }
      )
    );
  };
  return (
    <>
      <Card>
        <Box mb={20} flexBox alCenter jSpace>
          <Title size="medium">Top Product</Title>
          {/* <Button
            title="Add Product"
            onClick={() => {
              navigate(routes["Add Product"].path);
            }}
          /> */}
        </Box>
        <Box>
          <Table
            columns={[
              {
                field: "product_details",
                name: "Image",
                render: (rowData: any) => (
                  <img
                    crossOrigin="anonymous"
                    alt={rowData.name}
                    src={`${FILE_URL}/product/${rowData?.image[0]}`}
                    style={{
                      height: 100,
                      borderRadius: 4,
                      width: 100,
                      objectFit: "cover",
                    }}
                  />
                ),
              },

              {
                field: "product_details",
                name: "name",
                render: (rowData: any) => `${rowData.name}`,
              },

              {
                field: "product_details",
                name: "model",
                render: (rowData: any) => `${rowData.model}`,
              },
            ]}
            data={topProductList}
            // dataLoader={false}
            totalCount={topProductTotalCount}
            actions
            // onViewHandler={() => navigate(routes["View Vacancy"].path)}
            onDeleteHandler={onDeleteHandler}
            deleteLoader={deleteLoading}
            onEditHandler={editHandler}
            onUpdateHandler={updateHandler}
          />
        </Box>
      </Card>
    </>
  );
};
