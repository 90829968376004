interface KeyValueTableProps {
  data: { title: string; value: any }[];
  padding?: number;
  fontSize?: number;
  fullWidth?: any;
}
export const KeyValueTable = ({
  data,
  padding,
  fontSize,
  fullWidth,
}: KeyValueTableProps) => {
  return (
    <>
      <table
        className="keyvalue-table-container"
        style={{ width: fullWidth && "100%" }}
      >
        <tbody>
          {data.map(({ title, value }: any, index: any) => {
            if (value !== undefined && value !== "" && value !== null) {
              return (
                <tr key={index}>
                  <td
                    style={{
                      fontSize,
                      padding,
                    }}
                  >
                    {title}
                  </td>
                  <td>
                    {
                      <div
                        style={{
                          fontSize,
                          padding,
                        }}
                      >
                        {value !== undefined && value !== "" && value !== null
                          ? value
                          : "-"}
                      </div>
                    }
                  </td>
                </tr>
              );
            }
            return <></>;
          })}
        </tbody>
      </table>
    </>
  );
};
