import { useState } from "react";
import { useNavigation } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useInput } from "../../../hooks";
import { addCategoryAction, editCategoryAction } from "../../../redux";
import { isValid, validator } from "../../../utils";

import {
  Box,
  Button,
  FormInput,
  InputField,
  Media,
  Card,
  ActivityIndicator,
} from "../../common";
import { FILE_URL } from "../../../config";
export const InputFormComponent = ({ detail }: any) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const [image, setImage] = useState([]);
  const [removedImage, setRemovedImage] = useState([]);
  const dispatch = useDispatch();
  const { addLoading, editLoading } = useSelector(
    (state?: any) => state.category
  );

  const { data, onChangeHandler } = useInput({
    name: detail?.name ?? "",
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    const catchedErros = {};
    const validate = validator(catchedErros);

    const { name } = data;
    // VALIDATION
    const validateImage = () => {
      if (!!detail) {
        return (
          (image?.length === 0 &&
            removedImage?.length === detail?.image?.length) ||
          (image?.length === 0 &&
            removedImage?.length === 0 &&
            !!detail?.image?.length === false)
        );
      } else {
        return image?.length === 0;
      }
    };

    validate("imageFile", validateImage(), () => {
      toast.error("Image is Required");
    });
    validate("name", name?.length === 0, () => {
      toast.error("name is Required");
    });

    if (!isValid(catchedErros)) {
      console.error(catchedErros);
      return;
    }

    const formdata = new FormData();
    if (!detail || (!!detail && name !== detail.name)) {
      formdata.append("name", name);
    }

    image.length > 0 &&
      image.forEach((element) => {
        formdata.append("image", element.file);
      });

    // const photoFormdata = new FormData();

    // if (!!detail) {
    //   photoFormdata.append("type", "employment");

    //   image.length > 0 &&
    //     image.forEach((element) => {
    //       photoFormdata.append("imageFile", element.file);
    //     });
    //   removedImage?.length > 0 &&
    //     photoFormdata.append("removeIndex", JSON.stringify(removedImage));
    // }

    !!detail
      ? dispatch(editCategoryAction(detail.id, formdata, goBackHandler))
      : dispatch(addCategoryAction(formdata, goBackHandler));
  };

  const goBackHandler = () => {
    // history.goBack();
    navigate(routes["Category"].path);
  };

  return (
    <form onSubmit={onSubmit}>
      <Box flexBox>
        {/* <Box flex={1} /> */}
        <Box flex={2} flexBox vertical columnGap={20}>
          <Card title="Form">
            <Box flexBox vertical rowGap={20}>
              <FormInput label="Category Name" required>
                <InputField
                  placeholder="Category Name"
                  name="name"
                  value={data.name}
                  onChange={onChangeHandler("name")}
                  type="text"
                  // error={error?.name}
                  required
                />
              </FormInput>

              {/* <Box flexBox jEnd alCenter>
                <ActivityIndicator animating={false}>
                  <Button title="Submit" type="submit" />
                </ActivityIndicator>
              </Box> */}
            </Box>
          </Card>

          <Card>
            <Box flexBox vertical rowGap={20}>
              <Box flexBox jEnd alCenter>
                <ActivityIndicator animating={addLoading || editLoading}>
                  <Button.Ripple title="Submit" type="submit" />
                </ActivityIndicator>
              </Box>
            </Box>
          </Card>
        </Box>
        {/* <Box flex={1} /> */}
        <Box flex={2}>
          <Card title="Media">
            <Media
              setImage={setImage}
              // defaultImages={images}
              defaultImages={
                !!detail?.image ? [`${FILE_URL}/category/${detail?.image}`] : []
              }
              removedImage={removedImage}
              setRemovedImage={setRemovedImage}
            />
          </Card>
        </Box>
      </Box>
    </form>
  );
};
