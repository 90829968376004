import { useEffect } from "react";
import { useNavigation, useQuery } from "react-auth-navigation";
import { useDispatch, useSelector } from "react-redux";
import { FILE_URL } from "../../../../config";
import { getCategoryListAction } from "../../../../redux";

import { CompWrapper, Title, Card, Box, Table, Button } from "../../../common";

export const ListCategory = () => {
  const {
    navigation: { navigate, routes },
    // history,
    // location,
  } = useNavigation();
  const query: any = useQuery();
  const dispatch = useDispatch();

  const pageNumber = query?.page || 1;

  const { /* categoryListLoading, */ categoryList, totalCount } = useSelector(
    (state: any) => state.category
  );

  useEffect(() => {
    dispatch(getCategoryListAction(pageNumber));
  }, [pageNumber]);

  // const onDeleteHandler = (item: any, callback: any) => {
  //   dispatch(
  //     deleteCategoryAction(item.category_details.id, () => {
  //       if (categoryList.length === 1) {
  //         dispatch({ type: DELETE_CATEGORY.CLEAR });
  //         history?.replace(location.pathname + `?page=${pageNumber - 1}`);
  //       } else {
  //         dispatch({
  //           type: DELETE_CATEGORY.SUCCESS,
  //           payload: item.category_details.id,
  //         });
  //       }
  //       callback();
  //     })
  //   );
  // };

  const editHandler = (item: any) => {
    navigate(`${routes["Category"].path}/edit/${item.category_details.id}`);
  };

  const onViewHandler = (data: any) => {
    navigate(`${routes["Category"].path}/view/${data.category_details.id}`);
    // console.log("data", data);
  };
  return (
    <CompWrapper>
      <Card>
        <Box mb={20} flexBox alCenter jSpace>
          <Title size="medium">Category</Title>
          <Button
            title="Add Category"
            onClick={() => {
              navigate(routes["Add Category"].path);
            }}
          />
        </Box>
        <Box>
          <Table
            columns={[
              {
                field: "category_details",
                name: "Image",
                render: (rowData: any) => (
                  <img
                    crossOrigin="anonymous"
                    alt={rowData?.name}
                    src={`${FILE_URL}/category/${rowData?.image}`}
                    style={{ height: 100, borderRadius: 4 }}
                  />
                ),
              },

              {
                field: "category_details",
                name: "Name",
                render: (rowData: any) => `${rowData.name}`,
              },
            ]}
            data={categoryList}
            dataLoader={false}
            totalCount={totalCount}
            actions
            onViewHandler={onViewHandler}
            // onDeleteHandler={onDeleteHandler}
            // deleteLoader={deleteLoading}
            onEditHandler={editHandler}
          />
        </Box>
      </Card>
    </CompWrapper>
  );
};
