import { createActionSet } from "../../helpers";

export const LOGIN = createActionSet("LOGIN");
export const LOGOUT = createActionSet("LOGOUT");
export const PASSWORD = createActionSet("PASSWORD");
export const USER = createActionSet("USER");
export const EDIT_USER_INFO = createActionSet("EDIT_USER_INFO");
export const RESET_PASSWORD = createActionSet("RESET_PASSWORD");

export const FORGET_PASSWORD = createActionSet("FORGET_PASSWORD");
export const CHANGE_PASSWORD = createActionSet("CHANGE_PASSWORD");

export const CATEGORY_LIST = createActionSet("CATEGORY_LIST");
export const ALL_CATEGORY_LIST = createActionSet("ALL_CATEGORY_LIST");
export const CATEGORY_DETAIL = createActionSet("CATEGORY_DETAIL");
export const ADD_CATEGORY = createActionSet("ADD_CATEGORY");
export const EDIT_CATEGORY = createActionSet("EDIT_CATEGORY");
export const DELETE_CATEGORY = createActionSet("DELETE_CATEGORY");

export const SUB_CATEGORY_LIST = createActionSet("SUB_CATEGORY_LIST");
export const ALL_SUB_CATEGORY_LIST = createActionSet("ALL_SUB_CATEGORY_LIST");
export const SUB_CATEGORY_DETAIL = createActionSet("SUB_CATEGORY_DETAIL");
export const ADD_SUB_CATEGORY = createActionSet("ADD_SUB_CATEGORY");
export const EDIT_SUB_CATEGORY = createActionSet("EDIT_SUB_CATEGORY");
export const DELETE_SUB_CATEGORY = createActionSet("DELETE_SUB_CATEGORY");

export const TOP_PRODUCT_LIST = createActionSet("TOP_PRODUCT_LIST");
export const PRODUCT_LIST = createActionSet("PRODUCT_LIST");
export const ALL_PRODUCT_LIST = createActionSet("ALL_PRODUCT_LIST");
export const PRODUCT_DETAIL = createActionSet("PRODUCT_DETAIL");
export const ADD_PRODUCT = createActionSet("ADD_PRODUCT");
export const EDIT_PRODUCT = createActionSet("EDIT_PRODUCT");
export const DELETE_PRODUCT = createActionSet("DELETE_PRODUCT");

export const ORDER_LIST = createActionSet("ORDER_LIST");
export const ALL_ORDER_LIST = createActionSet("ALL_ORDER_LIST");
export const ORDER_DETAIL = createActionSet("ORDER_DETAIL");
export const ADD_ORDER = createActionSet("ADD_ORDER");
export const EDIT_ORDER = createActionSet("EDIT_ORDER");
export const DELETE_ORDER = createActionSet("DELETE_ORDER");
export const ORDER_PRODUCT_LIST = createActionSet("ORDER_PRODUCT_LIST");
export const ACCEPTED_PRODUCT_LIST = createActionSet("ACCEPTED_PRODUCT_LIST");
export const CLOSED_ORDER_LIST = createActionSet("CLOSED_ORDER_LIST");
export const UNFULFILLED_ORDER_LIST = createActionSet("UNFULFILLED_ORDER_LIST");
export const FULFILLED_ORDER_LIST = createActionSet("FULFILLED_ORDER_LIST");
export const UNFULFILLED_PRODUCT_LIST = createActionSet(
  "UNFULFILLED_PRODUCT_LIST"
);
export const UPDATE_ORDER_STATUS = createActionSet("UPDATE_ORDER_STATUS");
export const UPDATE_ORDER_PRODUCT_LIST = createActionSet(
  "UPDATE_ORDER_PRODUCT_LIST"
);

export const SEND_NOTIFICATION = createActionSet("SEND_NOTIFICATION");

export const TEAM_MEMBER_LIST = createActionSet("TEAM_MEMBER_LIST");
// export const ALL_CATEGORY_LIST = createActionSet("ALL_CATEGORY_LIST");
export const TEAM_DETAIL = createActionSet("TEAM_DETAIL");
export const ADD_TEAM_MEMBER = createActionSet("ADD_TEAM_MEMBER");
export const EDIT_TEAM_MEMBER = createActionSet("EDIT_TEAM_MEMBER");
export const DELETE_TEAM_MEMBER = createActionSet("DELETE_TEAM_MEMBER");

export const ENQUIRY_LIST = createActionSet("ENQUIRY_LIST");
export const ENQUIRY_DETAIL = createActionSet("ENQUIRY_DETAIL");
export const DELETE_ENQUIRY = createActionSet("DELETE_ENQUIRY");
