import toast from "react-hot-toast";
import { DELETE_ENQUIRY, ENQUIRY_DETAIL, ENQUIRY_LIST } from ".";
import { api, APIS, TABLE_LIMIT } from "../../config";

export function getEnquiryListAction(page = 1) {
  return async function (dispatch?: any) {
    let res;
    try {
      if (page === 1) {
        dispatch({ type: ENQUIRY_LIST.CLEAR });
      }
      dispatch({ type: ENQUIRY_LIST.LOADING });
      res = await api(`${APIS.enquiry}?page=${page}&limit=${TABLE_LIMIT}`);
      const { success, data } = res.data;
      if (success) {
        dispatch({ type: ENQUIRY_LIST.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: ENQUIRY_LIST.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: ENQUIRY_LIST.ERROR });
      console.error(message);
      // return 0;
    }
  };
}

export function getEnquiryDetailAction(id?: any) {
  return async function (dispatch?: any) {
    let res;
    try {
      dispatch({ type: ENQUIRY_DETAIL.LOADING });
      res = await api(`${APIS.enquiry}/${id}`);
      const { success, data } = res.data;
      console.log("data", data);
      if (success) {
        dispatch({ type: ENQUIRY_DETAIL.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: ENQUIRY_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: ENQUIRY_DETAIL.ERROR });
      console.error(message);
      // return 0;
    }
  };
}

export function deleteEnquiryAction(id?: any, callback?: any) {
  return async function (dispatch?: any) {
    let res;
    try {
      dispatch({ type: DELETE_ENQUIRY.LOADING });
      res = await api(`${APIS.enquiry}/${id}`, "DELETE");

      const { success, data } = res.data;

      if (success) {
        callback && callback();
        // dispatch({ type: DELETE_ENQUIRY.SUCCESS, payload: id });
        toast.success("Category successfully deleted");
        // dispatch(getCategoryAction(page));
      } else {
        dispatch({ type: DELETE_ENQUIRY.ERROR });
        toast.error(data.message);
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_ENQUIRY.ERROR });
      toast.error("Error Deleting Category");
      console.error(message);
      // return 0;
    }
  };
}
