import {
  PRODUCT_LIST,
  PRODUCT_DETAIL,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  TOP_PRODUCT_LIST,
} from "../actions";

const initalState: any = {
  productListLoading: false,
  productList: [],
  totalCount: null,

  topProductListLoading: true,
  topProductList: [],
  topProductTotalCount: null,

  detail: null,
  detailLoading: false,

  addLoading: false,

  editLoading: false,

  deleteLoading: false,
};

export function productReducer(state = initalState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_LIST.CLEAR:
      return {
        ...state,
        productListLoading: true,
        productList: [],
      };
    case PRODUCT_LIST.LOADING:
      return { ...state, productListLoading: true };
    case PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        productListLoading: false,
        productList: payload.rows,
        totalCount: Number(payload.rows?.[0]?.total_count),
      };
    case PRODUCT_LIST.ERROR:
      return { ...state, productListLoading: false };

    case TOP_PRODUCT_LIST.CLEAR:
      return {
        ...state,
        topProductListLoading: true,
        topProductList: [],
      };
    case TOP_PRODUCT_LIST.LOADING:
      return { ...state, topProductListLoading: true };
    case TOP_PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        topProductListLoading: false,
        topProductList: payload.rows,
        topProductTotalCount: Number(payload.rows?.[0]?.total_count),
      };
    case TOP_PRODUCT_LIST.ERROR:
      return { ...state, topProductListLoading: false };

    case PRODUCT_DETAIL.LOADING:
      return { ...state, detailLoading: true, detail: null };
    case PRODUCT_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detail: payload,
      };
    case PRODUCT_DETAIL.ERROR:
      return { ...state, detailLoading: false };
    case ADD_PRODUCT.LOADING:
      return { ...state, addLoading: true };
    case ADD_PRODUCT.SUCCESS:
      return {
        ...state,
        addLoading: false,
      };
    case ADD_PRODUCT.ERROR:
      return { ...state, addLoading: false };

    case EDIT_PRODUCT.LOADING:
      return { ...state, editLoading: true };
    case EDIT_PRODUCT.SUCCESS:
      return {
        ...state,
        editLoading: false,
      };
    case EDIT_PRODUCT.ERROR:
      return { ...state, editLoading: false };

    case DELETE_PRODUCT.LOADING:
      return { ...state, deleteLoading: true };
    case DELETE_PRODUCT.SUCCESS: {
      const productListClone = [...state.productList].filter(
        ({ product_details }) => product_details.id !== payload
      );
      return {
        ...state,
        deleteLoading: false,
        productList: productListClone,
      };
    }
    case DELETE_PRODUCT.CLEAR: {
      return {
        ...state,
        deleteLoading: false,
      };
    }

    case DELETE_PRODUCT.ERROR:
      return { ...state, deleteLoading: false };

    default:
      return state;
  }
}
